import { createReducer } from "@reduxjs/toolkit"
import { STANDARD_TYPES } from './standard.types'

const INITIAL_STATE = {
    filters: {
        ean: "",
        autore: "",
        titolo: "",
        tipoProdotto: [],
        editori: []
    },
    results: null,
    isPublishersManuallyEmpty: false,
    isLoading: false,
    selectedResults: [],
    error: null,
    isSelectMode: false,
    isPublishersSelectMode: false,
    searchId: null,
    isSearchIdLoading: false,
    isCardView: null
}

export const standardReducer = createReducer(INITIAL_STATE, {
    [STANDARD_TYPES.SET_EAN]: (state, action) => { state.filters.ean = action.payload },
    [STANDARD_TYPES.SET_AUTHOR]: (state, action) => { state.filters.autore = action.payload },
    [STANDARD_TYPES.SET_TITLE]: (state, action) => { state.filters.titolo = action.payload },
    [STANDARD_TYPES.SET_TYPE]: (state, action) => { state.filters.tipoProdotto = action.payload },
    [STANDARD_TYPES.CLEAR_FILTERS]: (state) => { state.filters = INITIAL_STATE.filters; state.isPublishersManuallyEmpty = false },
    [STANDARD_TYPES.SET_STANDARD_RESULTS]: (state, action) => { state.results = action.payload; state.isLoading = false },
    [STANDARD_TYPES.FETCH_STANDARD_START]: (state) => { state.isLoading = true; state.results = INITIAL_STATE.results; state.selectedResults = INITIAL_STATE.selectedResults },
    [STANDARD_TYPES.FETCH_STANDARD_FAILURE]: (state, action) => { state.isLoading = false; state.error = action.payload },
    [STANDARD_TYPES.ADD_STANDARD_SELECTED_RESULTS]: (state, action) => { state.searchId = null; state.selectedResults.push(action.payload) },
    [STANDARD_TYPES.REMOVE_STANDARD_SELECTED_RESULTS]: (state, action) => { state.searchId = null; state.selectedResults = state.selectedResults.filter((ean) => action.payload !== ean) },
    [STANDARD_TYPES.SET_SELECT_MODE]: (state, action) => { state.isSelectMode = action.payload },
    [STANDARD_TYPES.SELECT_ALL_RESULTS]: (state) => { state.selectedResults = state.results.map(result => result.ean) },
    [STANDARD_TYPES.DESELECT_ALL_RESULTS]: (state) => { state.selectedResults = INITIAL_STATE.selectedResults },
    [STANDARD_TYPES.FETCH_SEARCHID_START]: (state) => { state.isSearchIdLoading = true },
    [STANDARD_TYPES.SET_SEARCHID]: (state, action) => { state.isSearchIdLoading = false; state.searchId = action.payload },
    [STANDARD_TYPES.SET_PUB_SELECT_MODE]: (state, action) => { state.isPublishersSelectMode = action.payload },
    [STANDARD_TYPES.ADD_PUBLISHER_SELECTED]: (state, action) => { state.filters.editori.push(action.payload) },
    [STANDARD_TYPES.REMOVE_PUBLISHER_SELECTED]: (state, action) => { if (state.filters.editori.length - 1 <= 0) { state.isPublishersManuallyEmpty = true } state.filters.editori = state.filters.editori.filter((pub) => action.payload.code !== pub.code); },
    [STANDARD_TYPES.SELECT_ALL_PUBLISHERS]: (state, action) => { state.filters.editori = action.payload.map(pub => pub); },
    [STANDARD_TYPES.DESELECT_ALL_PUBLISHERS]: (state) => { state.filters.editori = INITIAL_STATE.filters.editori; state.isPublishersManuallyEmpty = true },
    [STANDARD_TYPES.SET_STANDARD_VIEW_TYPE]: (state, action) => { state.isCardView = action.payload },
    [STANDARD_TYPES.SET_SELECTED_RESULTS]: (state, action) => { state.selectedResults = action.payload }
})