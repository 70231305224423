import {takeLatest, call, put, all, select} from "redux-saga/effects";
import { USER_TYPES } from './user.types'
import {setUser, fetchLoginFailure, setFavPublisher} from './user.actions'
import {getFakeLogin, getFavPublisher, getLogin} from '../../services/apis.service'

export function* fetchLoginStartSagaProcess() {
    try {
        const isDev = process.env.NODE_ENV === "development"
        const apiCall = isDev ? getFakeLogin : getLogin;
        const { data } = yield call(apiCall)
        if (4 in data.funzionalita) {
            yield put(setUser(data))
        } else {
            yield put(fetchLoginFailure(418)) //i'm a teapot :)
        }
    } catch (err) {
        yield put(fetchLoginFailure(err.response.status))
    }
}

export function* fetchLoginStartSaga() {
    yield takeLatest(
        USER_TYPES.FETCH_LOGIN_START,
        fetchLoginStartSagaProcess
    );
}

/**
 * INFO UTILI PER DIRITTI:
 * In questa sezione non abbiamo alcun riferimento salvato,
 * La soluzione che è stata adotatta per il momento è richiamare la login per prendere le info
 * dell'utente, in particolare lo username.
 *
 * NON ho potuto lanciare due chiamate nel metodo della login perchè questo tipo di sistema non
 * lo permette.
 *
 * Per salvare le informazioni, ho dovuto aggiungere una variabile nuova e ho riutilizzato
 * FETCH_LOGIN_START per poter richiamare questo metodo con quello della login.
 *
 * Altra INFO utili (per migliorare anche il codice):
 * [QUESTA SEZIONE è UNA RIFLESSIONE, DA TESTARE] <----------------------------------------------
 *
 * creare una serie di costanti in questi file, così da richiamare le funzioni una volta sola(?)
 * creare un metodo unico di fetch e richiamare all'interno x° yield
 *
 * */
export function* fetchFavPublisherProcess() {
    try {
        const isDev = process.env.NODE_ENV === "development"
        const apiCall = isDev ? getFakeLogin : getLogin;
        const { data } = yield call(apiCall)
        const response = yield call(getFavPublisher, data.username)
        yield put(setFavPublisher(response.data))
    } catch (err) {
        yield put(fetchLoginFailure(err))
    }
}

export function* fetchFavPublisher() {
    yield takeLatest(
        USER_TYPES.FETCH_LOGIN_START,
        fetchFavPublisherProcess
    );
}

export function* userSagas() {
    yield all([
        call(fetchLoginStartSaga),
        call(fetchFavPublisher)
    ]);
}