import React, { Suspense, lazy } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Switch, Route, Redirect } from "react-router-dom";
import { URLS, PROTECTED_URL } from "../constants/pathnames.constant";
import LoginPage from "../pages/login.page";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { selectCurrentUser } from "../redux/user/user.selectors";
import { selectIsSettingsReady } from "../redux/settings/settings.selectors";
import ErrorBoundary from "../components/error-boundary.component";
import styled from "styled-components";

/* --------------------------- CONDITIONAL IMPORTS --------------------------- */

const ProtectedRoute = lazy(() => import("./protected.route"));

/* --------------------------------- STYLES --------------------------------- */

const SpinContainer = styled.div({
  display: "flex",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

/* ---------------------------------- MAIN ---------------------------------- */

export default function MainRoute() {
  const currentUser = useSelector(selectCurrentUser);
  const isSettingsReady = useSelector(selectIsSettingsReady);

  /* --------------------------------- RETURN --------------------------------- */

  return !currentUser ? (
    <Switch>
      <Route path={[URLS.LOGIN.url, "/*"]}>
        <LoginPage />
      </Route>
    </Switch>
  ) : isSettingsReady ? (
    <ErrorBoundary>
      <Suspense
        fallback={
          <SpinContainer>
            <Spin
              size="large"
              indicator={<LoadingOutlined style={{ color: "black" }} spin />}
            />
          </SpinContainer>
        }
      >
        <Switch>
          <Route path={PROTECTED_URL}>
            <ProtectedRoute />
          </Route>
          <Route>
            <Redirect to={URLS.STANDARD_FILTERS.url} />
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  ) : (
    "spin"
  );
}
