export const NAVBAR = {
    SISMINO: "Sismino",
    LOGIN: "Login",
    FILTERS: "Filtri",
    SOLD_REPORT: "Distr. Venduto",
    TOTALIZE: "Totalizza",
    DETAILS_TITLE: "Dettaglio titolo",
    PUBLISHERS: "Editori"
}

export const RESULT_CARD = {
    TITLE: "Titolo",
    AUTHOR: "Autore",
    RELEASE_DATE: "Data uscita",
    PUBLISHER: "Editore",
    COLLECTION: "Collana",
    PRINTING: "Tiratura",
    STOCK: "Giacenza",
    SOLD: "Venduto",
    SELLOUT: "%sellout",
    PRODUCT_TYPE: "Tipo prodotto",
    SOLD_GFK: "Sellout GFK",
    NOT_AVAILABLE: "N/A",
    SOLD_GFK_U12M: "GFK U12M"
}

export const ERROR_BOUNDARY = {
    SOMETHING_WRONG_TITLE: "Qualcosa è andato storto",
    SOMETHING_WRONG_DESC: "Aggiorna la pagina, se il problema persiste contatta un amministratore",
    REFRESH: "Aggiorna pagina",

}