import { createAction } from "@reduxjs/toolkit"
import { STANDARD_TYPES } from './standard.types'

export const setEan = createAction(STANDARD_TYPES.SET_EAN)
export const setAuthor = createAction(STANDARD_TYPES.SET_AUTHOR)
export const setTitle = createAction(STANDARD_TYPES.SET_TITLE)
export const setTypes = createAction(STANDARD_TYPES.SET_TYPE)
export const clearFilters = createAction(STANDARD_TYPES.CLEAR_FILTERS)
export const fetchStandardStart = createAction(STANDARD_TYPES.FETCH_STANDARD_START)
export const fetchStandardFailure = createAction(STANDARD_TYPES.FETCH_STANDARD_FAILURE)
export const setStandardResults = createAction(STANDARD_TYPES.SET_STANDARD_RESULTS)
export const addStandardSelectedResults = createAction(STANDARD_TYPES.ADD_STANDARD_SELECTED_RESULTS)
export const removeStandardSelectedResults = createAction(STANDARD_TYPES.REMOVE_STANDARD_SELECTED_RESULTS)
export const setSelectMode = createAction(STANDARD_TYPES.SET_SELECT_MODE)
export const setSelectedResults = createAction(STANDARD_TYPES.SET_SELECTED_RESULTS)
export const selectAllResults = createAction(STANDARD_TYPES.SELECT_ALL_RESULTS)
export const deselectAllResults = createAction(STANDARD_TYPES.DESELECT_ALL_RESULTS)
export const fetchSearchIdStart = createAction(STANDARD_TYPES.FETCH_SEARCHID_START)
export const setSearchId = createAction(STANDARD_TYPES.SET_SEARCHID)
export const fetchFavouriteTypesStart = createAction(STANDARD_TYPES.FETCH_FAVOURITE_TYPES_START)
export const setPublishersSelectMode = createAction(STANDARD_TYPES.SET_PUB_SELECT_MODE)
export const addPublishersSelected = createAction(STANDARD_TYPES.ADD_PUBLISHER_SELECTED)
export const removePublishersSelected = createAction(STANDARD_TYPES.REMOVE_PUBLISHER_SELECTED)
export const selectAllPublishers = createAction(STANDARD_TYPES.SELECT_ALL_PUBLISHERS)
export const deselectAllPublishers = createAction(STANDARD_TYPES.DESELECT_ALL_PUBLISHERS)
export const setIsCardViewType = createAction(STANDARD_TYPES.SET_STANDARD_VIEW_TYPE)