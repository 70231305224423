import { createSelector } from "@reduxjs/toolkit";
import { convertSelectedDataIntoGraphData } from '../../services/converter.services'

const selectStandard = (state) => state.standard;

export const selectEanFilter = createSelector(
    [selectStandard],
    (standard) => standard.filters.ean
);

export const selectAuthorFilter = createSelector(
    [selectStandard],
    (standard) => standard.filters.autore
);

export const selectTitleFilter = createSelector(
    [selectStandard],
    (standard) => standard.filters.titolo
);

export const selectTypesFilter = createSelector(
    [selectStandard],
    (standard) => standard.filters.tipoProdotto
);

export const selectPublishersFilter = createSelector(
    [selectStandard],
    (standard) => standard.filters.editori
);

export const selectPublishersFilterLength = createSelector(
    [selectStandard],
    (standard) => standard.filters.editori.length
);

export const selectFilters = createSelector(
    [selectStandard],
    (standard) => standard.filters
)

export const selectStandardResults = createSelector(
    [selectStandard],
    (standard) => standard.results
);

export const selectStandardSelResults = createSelector(
    [selectStandard],
    (standard) => standard.selectedResults
);

export const selectStandardSelResultsLength = createSelector(
    [selectStandard],
    (standard) => standard.selectedResults.length
);

export const selectIsAllSelected = createSelector(
    [selectStandard],
    (standard) => standard.selectedResults.length === (!standard.results || standard.results.length)
);

export const selectIsLoading = createSelector(
    [selectStandard],
    (standard) => standard.isLoading
)

export const selectIsSelectMode = createSelector(
    [selectStandard],
    (standard) => standard.isSelectMode
)

export const selectedApiReadyFilters = createSelector(
    [selectStandard],
    (standard) => {
        return {
            ean: standard.filters.ean?.trim() || null,
            titolo: standard.filters.titolo?.trim() || null,
            autore: standard.filters.autore?.trim() || null,
            tipoProdotto: standard.filters.tipoProdotto,
            editore: standard.filters.editori.map(pub => pub.code)
        }
    }
)

export const selectDataForReport = createSelector(
    [selectStandard],
    (standard) => convertSelectedDataIntoGraphData(standard.selectedResults, standard.results)
)

export const selectSearchId = createSelector(
    [selectStandard],
    (standard) => standard.searchId
)

export const selectIsSearchIdLoading = createSelector(
    [selectStandard],
    (standard) => standard.isSearchIdLoading
)

export const selectIsPublisherSelectMode = createSelector(
    [selectStandard],
    (standard) => standard.isPublishersSelectMode
)

export const selectIsPublishersManuallyEmpty = createSelector(
    [selectStandard],
    (standard) => standard.isPublishersManuallyEmpty
)

export const selectIsCardViewType = createSelector(
    [selectStandard],
    (standard) => standard.isCardView
)