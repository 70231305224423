import { takeLatest, call, put, all } from "redux-saga/effects";
import { SETTINGS_TYPES } from './settings.types'
import { setSettings, fetchSettingsFailure } from './settings.actions'
import { getSettings } from '../../services/apis.service'

export function* fetchSettingsStartSagaProcess() {
    try {
        const response = yield call(getSettings)
        yield put(setSettings(response.data))
    } catch (err) {
        yield put(fetchSettingsFailure(err))
    }
}

export function* fetchSettingsStartSaga() {
    yield takeLatest(
        SETTINGS_TYPES.FETCH_SETTINGS_START,
        fetchSettingsStartSagaProcess
    );
}

export function* settingsSagas() {
    yield all([
        call(fetchSettingsStartSaga),
    ]);
}