import React, { Component } from "react";

import styled from "styled-components";
import { Result, Button } from "antd";
import { ERROR_BOUNDARY } from "../constants/components.constant";

/* --------------------------------- STYLES --------------------------------- */

const ErrorContainer = styled.div({
  display: "flex",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

/* ---------------------------------- MAIN ---------------------------------- */

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /* ------------------------ COMPONENT CLASS FUNCTIONS ----------------------- */

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  /* --------------------------------- RETURN --------------------------------- */

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <Result
            status="500"
            title={ERROR_BOUNDARY.SOMETHING_WRONG_TITLE}
            subTitle={ERROR_BOUNDARY.SOMETHING_WRONG_DESC}
            extra={
              <Button onClick={() => window.location.reload()} type="primary">
                {ERROR_BOUNDARY.REFRESH}
              </Button>
            }
          />
        </ErrorContainer>
      );
    }
    return this.props.children;
  }
}
