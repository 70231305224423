import React, { useEffect } from 'react'

import MainRoute from './routes/main.route'
import Navbar from './components/navbar-expandable.component'
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettingsStart } from './redux/settings/settings.actions'
import { selectCurrentUser } from './redux/user/user.selectors'

/* --------------------------------- STYLES --------------------------------- */

const Content = styled.div({
  height: "100%",
  display: 'flex',
  flexDirection: "column"
});

const PageContent = styled.div({
  display: "flex",
  padding: "0 10px",
  flex: 1,
  marginTop: 48,
  flexDirection: "column"
})

/* ---------------------------------- MAIN ---------------------------------- */

export default function App() {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)

  /* ------------------------------- USE EFFECTS ------------------------------ */

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchSettingsStart())
    }
    // eslint-disable-next-line
  }, [currentUser])

  /* --------------------------------- RETURN --------------------------------- */

  return (
    <Content>
      <Navbar />
      <PageContent>
        <MainRoute />
      </PageContent>
    </Content>
  );
}

