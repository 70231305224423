import { createSelector } from "@reduxjs/toolkit";
import { extractUserPublishersDataFromAllPub } from '../../services/extractor.services'
import { convertExtractedPubDataToPlainList } from '../../services/converter.services'

const selectUser = (state) => state.user;
const selectSettings = (state) => state.settings;

export const selectSUser = createSelector(
    [selectUser],
    (user) => user
);

export const selectCurrentUser = createSelector(
    [selectUser],
    (user) => user.currentUser
);

export const selectUserPublishers = createSelector(
    [selectUser],
    (user) => user.activePublishers
);

export const selectUserError = createSelector(
    [selectUser],
    (user) => user.error
);

export const selectUsername = createSelector(
    [selectUser],
    (user) => user.currentUser.username
);

export const selectFavPublisher = createSelector(
    [selectUser],
    (user) => user.favPublisher
);

export const selectUserPublishersWithCompleteData = createSelector(
    [selectUser, selectSettings],
    (user, settings) => convertExtractedPubDataToPlainList(extractUserPublishersDataFromAllPub(user.currentUser.sigle, settings.publishers))
);

export const selectAbilitatedFunctionalities = createSelector(
  [selectUser],
  (user) => user.currentUser.funzionalita
);