//EXTRACTORS GET DATA FROM MULTIPLE DIFFERENT PLACES AND UNIFY IT, SO IT CAN BE PASSED TO A COMPARATOR OR CONVERTER FOR PROCESS

export function extractUserPublishersDataFromAllPub(userPublishers, allPublishers) {
    const data = Object.entries(userPublishers).map(([key, value]) => {
        if (allPublishers[key] && allPublishers[key].abilitato) {
            let cycleData = {
                name: allPublishers[key].editore,
                code: allPublishers[key].codice
            }
            if (value.length) {
                cycleData = {
                    ...cycleData, sub: value.map(ram => ({
                        code: allPublishers[key].rami[ram].codice,
                        name: allPublishers[key].rami[ram].nome,
                    }))
                }
            }
            return cycleData;
        } else {
            return null
        }
    })
    return data.filter(obj => obj !== null)
}