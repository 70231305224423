import React, { useState, useEffect } from "react";

import styled from "styled-components";
import {
  FilterFilled,
  CloseOutlined,
  ArrowLeftOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { NAVBAR } from "../constants/components.constant";
import {
  selectIsSelectMode,
  selectIsPublisherSelectMode,
} from "../redux/standard/standard.selectors";
import {
  setSelectMode,
  setPublishersSelectMode,
} from "../redux/standard/standard.actions";
import { selectCurrentUser } from "../redux/user/user.selectors";
import { URLS } from "../constants/pathnames.constant";
import SourceLogoSisma from "../assets/images/logo-sisma.png";

/* --------------------------------- STYLES --------------------------------- */

const navItemsHeight = "22px";

const NavbarWrapper = styled.div({
  width: "100%",
  height: 48,
  background: "#f3f3f3",
  position: "fixed",
  zIndex: 100,
});

const Navbar = styled.div({
  padding: 10,
  display: "flex",
  height: 48,
  alignItems: "center",
  justifyContent: "space-between",
});

const Title = styled.span({
  fontWeight: 600,
  fontSize: navItemsHeight,
});

const LeftNav = styled.div({
  display: "flex",
  alignItems: "center",
});

const SismaLogo = styled.img({
  height: navItemsHeight,
  opacity: 0.85,
  marginRight: "5px",
  verticalAlign: "sub",
});

/* ---------------------------------- MAIN ---------------------------------- */

export default function NavbarExpandable() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isPublisherSelectMode = useSelector(selectIsPublisherSelectMode);
  const isStandardSelectMode = useSelector(selectIsSelectMode);
  const history = useHistory();
  const location = useLocation();
  const [navData, setNavData] = useState({
    button: null,
    text: NAVBAR.SISMINO,
    isVisible: true,
  });

  /* -------------------------------- FUNCTIONS ------------------------------- */

  function checkUrlToDisplayCorrectData(path) {
    switch (path) {
      case URLS.STANDARD.url:
        return {
          button: isStandardSelectMode ? (
            <CloseOutlined
              onClick={() => dispatch(setSelectMode(false))}
              style={{ fontSize: navItemsHeight }}
            />
          ) : (
            <FilterFilled
              onClick={() => history.push(URLS.STANDARD_FILTERS.url)}
              style={{ fontSize: navItemsHeight }}
            />
          ),
          text: NAVBAR.SISMINO,
          isVisible: true,
        };

      case URLS.STANDARD_FILTERS.url: {
        return {
          button: (
            <ArrowLeftOutlined
              onClick={() => history.push(URLS.STANDARD.url)}
              style={{ fontSize: navItemsHeight }}
            />
          ),
          text: NAVBAR.FILTERS,
          isVisible: true,
        };
      }
      case URLS.PUBLISHER_FILTER.url: {
        return {
          button: isPublisherSelectMode ? (
            <CheckOutlined
              onClick={() => dispatch(setPublishersSelectMode(false))}
              style={{ fontSize: navItemsHeight }}
            />
          ) : (
            <ArrowLeftOutlined
              onClick={() => history.push(URLS.STANDARD_FILTERS.url)}
              style={{ fontSize: navItemsHeight }}
            />
          ),
          text: NAVBAR.PUBLISHERS,
          isVisible: true,
        };
      }
      case URLS.SOLD_REPORT.url: {
        return {
          button: (
            <ArrowLeftOutlined
              onClick={() => history.push(URLS.STANDARD.url)}
              style={{ fontSize: navItemsHeight }}
            />
          ),
          text: NAVBAR.SOLD_REPORT,
          isVisible: true,
        };
      }
      case URLS.DETAILS.url: {
        return {
          button: (
            <ArrowLeftOutlined
              onClick={() => history.push(URLS.STANDARD.url)}
              style={{ fontSize: navItemsHeight }}
            />
          ),
          text: NAVBAR.DETAILS_TITLE,
          isVisible: true,
        };
      }
      case URLS.TOTALIZE.url: {
        return {
          button: (
            <ArrowLeftOutlined
              onClick={() => history.push(URLS.STANDARD.url)}
              style={{ fontSize: navItemsHeight }}
            />
          ),
          text: NAVBAR.TOTALIZE,
          isVisible: true,
        };
      }
      default:
        return {
          button: null,
          text: NAVBAR.SISMINO,
          isVisible: true,
        };
    }
  }



  /* ------------------------------- USE EFFECTS ------------------------------ */

  useEffect(() => {
    let tmpVal;
    if (currentUser) {
      tmpVal = checkUrlToDisplayCorrectData(location.pathname);
    } else {
      tmpVal = {
        button: null,
        text: NAVBAR.LOGIN,
        isVisible: false,
      };
    }
    setNavData(tmpVal);
    // eslint-disable-next-line
  }, [
    location.pathname,
    currentUser,
    isStandardSelectMode,
    isPublisherSelectMode,
  ]);

  /* --------------------------------- RETURN --------------------------------- */

  return navData.isVisible ? (
    <NavbarWrapper>
      <Navbar>
        <LeftNav>
          <Title>
            <SismaLogo src={SourceLogoSisma} />
            {navData.text}
          </Title>
        </LeftNav>
        {navData.button}
      </Navbar>
    </NavbarWrapper>
  ) : null;
}
