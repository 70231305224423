import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { STANDARD_TYPES } from './standard.types'
import { setStandardResults, fetchStandardFailure, setSearchId, setTypes } from './standard.actions'
import { selectedApiReadyFilters, selectStandardSelResults } from './standard.selectors'
import { getStandardResults, getSearchId } from '../../services/apis.service'
import { selectUsername, selectAbilitatedFunctionalities } from "../user/user.selectors";
import { getFavouriteTypeFilter } from "../../services/apis.service";
import { BOOK_TYPES } from "../../constants/values.constant";
import { convertStandardFilters } from "../../services/converter.services";

export function* fetchStandardStartSagaProcess() {
    try {
        const filters = convertStandardFilters(yield select(selectAbilitatedFunctionalities), { ...yield select(selectedApiReadyFilters) })
        const response = yield call(getStandardResults, filters)
        yield put(setStandardResults(response.data))
    } catch (err) {
        yield put(fetchStandardFailure(err))
    }
}

export function* fetchSearchIdStartSagaProcess() {
    try {
        const response = yield call(getSearchId, yield select(selectStandardSelResults))
        yield put(setSearchId(response.data))
    } catch (err) {
        yield put(setSearchId(null))
    }
}

export function* fetchProductTypeOnClearOrCallStartProcess() {
    function loadAll() {
        put(
            setTypes([
                BOOK_TYPES.PHYSICAL.value,
                BOOK_TYPES.AUDIOBOOK.value,
                BOOK_TYPES.EBOOK.value,
            ])
        );
    }
    try {
        const { data } = yield call(getFavouriteTypeFilter, yield select(selectUsername));
        if (data && data[0].payload && data[0].payload.length) {
            yield put(setTypes(JSON.parse(data[0].payload)));
        } else {
            yield call(loadAll);
        }
    } catch {
        yield call(loadAll)
    }
}

export function* fetchProductTypeOnClearOrCallStart() {
    yield takeLatest(
        [STANDARD_TYPES.CLEAR_FILTERS, STANDARD_TYPES.FETCH_FAVOURITE_TYPES_START],
        fetchProductTypeOnClearOrCallStartProcess
    );
}

export function* fetchStandardStartSaga() {
    yield takeLatest(
        STANDARD_TYPES.FETCH_STANDARD_START,
        fetchStandardStartSagaProcess
    );
}

export function* fetchSearchIdStartSaga() {
    yield takeLatest(
        STANDARD_TYPES.FETCH_SEARCHID_START,
        fetchSearchIdStartSagaProcess
    )
}

export function* standartSagas() {
    yield all([
        call(fetchStandardStartSaga),
        call(fetchSearchIdStartSaga),
        call(fetchProductTypeOnClearOrCallStart)
    ]);
}