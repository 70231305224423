import React, { useEffect } from "react";

import { Button } from "antd";
import { fetchLoginStart } from "../redux/user/user.actions";
import { selectUserError } from "../redux/user/user.selectors";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import SourceLogoSisma from "../assets/images/logo-sisma.png";
import { LOGIN } from "../constants/pages.constant";

/* --------------------------------- STYLES --------------------------------- */

const imgHeightAndFontSize = 50;

const LoginContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  flex: 1,
  margin: "auto",
});

const LogoAndText = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: imgHeightAndFontSize,
  fontWeight: "bold",
  width: "90%",
  textAlign: "center",
});

const SismaLogo = styled.img({
  opacity: 0.85,
  marginRight: "5px",
  verticalAlign: "sub",
  height: imgHeightAndFontSize,
});

const ErrorContainer = styled.div({
  color: "#700000",
  marginBottom: 20,
});

const LoginButton = styled(Button)({
  width: 100,
});

/* ---------------------------------- MAIN ---------------------------------- */

export default function LoginPage() {
  const dispatch = useDispatch();
  const error = useSelector(selectUserError);

  /* ------------------------------- USE EFFECTS ------------------------------ */

  useEffect(() => {
    dispatch(fetchLoginStart());
    // eslint-disable-next-line
  }, []);

  /* -------------------------------- FUNCTIONS ------------------------------- */

  function showTextBasedOnError() {
    console.debug(error)
    switch (error) {
      case 500:
        return LOGIN.ERRORS[500];
      case 401:
        return LOGIN.ERRORS[401];
      case 418:
        return LOGIN.ERRORS[418];
      case 403:
        return LOGIN.ERRORS[403];
      case 404:
        return LOGIN.ERRORS[404];
      default:
        return LOGIN.ERRORS[401];
    }
  }

  /* --------------------------------- RETURN --------------------------------- */

  return (
    <LoginContainer>
      <LogoAndText>
        <SismaLogo src={SourceLogoSisma} />
        {LOGIN.SISMINO}
      </LogoAndText>
      {error ? (<ErrorContainer>{showTextBasedOnError()}</ErrorContainer>) : null}
      <LoginButton
        type="primary"
        onClick={() => window.location.replace("/sisma/saml/login")}
        size="large"
      >
        {LOGIN.LOGIN_BUTTON}
      </LoginButton>
    </LoginContainer>
  );
}
