//CONVERTERS TRANSFORM SOME DATA INTO DATA THAT COMPONENTS CAN UNDERSTAND

import { checkAbbreviationForBookType } from './comparator.services'
import { BOOK_ABBREVIATONS, SOLD_GEMS_MODE, SOLD_GFK_MODE, SOLD_VALUE_MODE } from '../constants/values.constant'

export function convertStandardFilters(refUserFunctionalitiesActive, refFilters) {
    let hasAudiolibri = false;
    if(refUserFunctionalitiesActive[8] != undefined){
        hasAudiolibri = true;
    }

    return {
        filtro: refFilters,
        hasAudiolibriPerm: hasAudiolibri
    }
}

export function convertAuthorsToAutocompleteOptions(authors) {
    return authors.flatMap((val, i) => (
        {
            label: val && val.autore ? val.autore : "",
            value: val && val.autore ? val.autore : i + "a"
        }))
}

export function convertTitlesToAutocompleteOptions(titles) {
    return titles.flatMap((val, i) => (
        {
            label: val && val.titolo ? val.titolo : "",
            value: val && val.titolo ? val.titolo : i + "a"
        }))
}

export function convertSelectedDataIntoGraphData(selectedArray, data) {
    if (data && data.length) {
        const calculatedData = {
            cartaceo: 0, cartaceoGFK: 0, cartaceoValore: 0,
            ebook: 0, ebookGFK: 0, ebookValore: 0,
            audiolibro: 0, audiolibroGFK: 0, audiolibroValore: 0
        }

        selectedArray.forEach((ean) => {
            const foundEanObj = data.find((obj) => obj.ean === ean);
            switch (checkAbbreviationForBookType(foundEanObj ? foundEanObj.tipoProdotto : null)) {
                case BOOK_ABBREVIATONS.NORMAL.name: {
                    calculatedData.cartaceo += foundEanObj.venduto ?? 0;
                    calculatedData.cartaceoGFK += foundEanObj.selloutGFK ?? 0;
                    calculatedData.cartaceoValore += foundEanObj.valoreVendutoTotale ?? 0;
                } break;
                case BOOK_ABBREVIATONS.EBOOK.name: {
                    calculatedData.ebook += foundEanObj.venduto ?? 0;
                    calculatedData.ebookGFK += foundEanObj.venduto ?? 0;
                    calculatedData.ebookValore += foundEanObj.valoreVendutoTotale ?? 0;
                } break;
                case BOOK_ABBREVIATONS.AUDIOBOOK.name: {
                    calculatedData.audiolibro += foundEanObj.venduto ?? 0;
                    calculatedData.audiolibroGFK += 0 + foundEanObj.venduto ?? 0;
                    calculatedData.audiolibroValore += foundEanObj.valoreVendutoTotale ?? 0;
                } break;
                default: break;
            }
        })
        return [
            {
                name: BOOK_ABBREVIATONS.NORMAL.name, value: {
                    [SOLD_GEMS_MODE]: calculatedData.cartaceo,
                    [SOLD_GFK_MODE]: calculatedData.cartaceoGFK,
                    [SOLD_VALUE_MODE]: calculatedData.cartaceoValore
                }
            },
            {
                name: BOOK_ABBREVIATONS.AUDIOBOOK.name, value: {
                    [SOLD_GEMS_MODE]: calculatedData.audiolibro,
                    [SOLD_GFK_MODE]: calculatedData.audiolibroGFK,
                    [SOLD_VALUE_MODE]: calculatedData.audiolibroValore
                }
            },
            {
                name: BOOK_ABBREVIATONS.EBOOK.name, value: {
                    [SOLD_GEMS_MODE]: calculatedData.ebook,
                    [SOLD_GFK_MODE]: calculatedData.ebookGFK,
                    [SOLD_VALUE_MODE]: calculatedData.ebookValore
                }
            },
        ]
    } else {
        return false
    }
}

/**
 * 
 * @param {*} extrData only works if the data passed is from the extractUserPublishersDataFromAllPub function in the extractor service file
 */
export function convertExtractedPubDataToPlainList(extrData) {
    if (extrData && extrData.length) {
        let plainList = []
        extrData.forEach(pub => {
            //plainList.push({ name: pub.name, code: pub.code })
            if (pub.sub) {
                pub.sub.forEach(sub => {
                    plainList.push({ name: sub.name, code: sub.code })
                })
            } else {
                plainList.push({ name: pub.name, code: pub.code })
            }
        })
        return plainList
    } else {
        return []
    }
}


