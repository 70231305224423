import { createSelector } from "@reduxjs/toolkit";

const selectSettings = (state) => state.settings;

export const selectIsSettingsReady = createSelector(
    [selectSettings],
    (settings) => settings.publishers && settings.functionality && settings.tableauDashboad && !settings.error
);

export const selectTotalizeTableauUrl = createSelector(
    [selectSettings],
    (settings) => settings.tableauDashboad.totalizzaMobile
)

export const selectDetailsTableauUrls = createSelector(
    [selectSettings],
    (settings) => ({
        cartaceo: settings.tableauDashboad.cartaceoMobile,
        ebook: settings.tableauDashboad.ebookMobile,
        audiolibro: settings.tableauDashboad.audiolibriMobile
    })
)

export const selectSsoTableauUrl = createSelector(
    [selectSettings],
    (settings) => settings.tableauDashboad.tableauSsoLogin
);