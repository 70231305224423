import { configureStore } from '@reduxjs/toolkit'

import { userReducer } from './user/user.reducer'
import { standardReducer } from './standard/standard.reducer'
import { settingsReducer } from './settings/settings.reducer'
import rootSaga from './root-saga'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: {
        user: userReducer,
        standard: standardReducer,
        settings: settingsReducer
    },
    middleware: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export default store