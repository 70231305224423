export const STANDARD_TYPES = {
    SET_EAN: "SET_EAN",
    SET_AUTHOR: "SET_AUTHOR",
    SET_TITLE: "SET_TITLE",
    SET_TYPE: "SET_TYPE",
    CLEAR_FILTERS: "CLEAR_FILTERS",
    FETCH_STANDARD_START: "FETCH_STANDARD_START",
    FETCH_STANDARD_FAILURE: "FETCH_STANDARD_FAILURE",
    SET_STANDARD_RESULTS: "SET_STANDARD_RESULTS",
    ADD_STANDARD_SELECTED_RESULTS: "ADD_STANDARD_SELECTED_RESULTS",
    REMOVE_STANDARD_SELECTED_RESULTS: "REMOVE_STANDARD_SELECTED_RESULTS",
    SET_SELECT_MODE: "SET_SELECT_MODE",
    SET_SELECTED_RESULTS: "SET_SELECTED_RESULTS",
    SELECT_ALL_RESULTS: "SELECT_ALL_RESULTS",
    DESELECT_ALL_RESULTS: "DESELECT_ALL_RESULTS",
    FETCH_SEARCHID_START: "FETCH_SEARCHID_START",
    SET_SEARCHID: "SET_SEARCHID",
    FETCH_FAVOURITE_TYPES_START: "FETCH_FAVOURITE_TYPES_START",
    SET_PUB_SELECT_MODE: "SET_PUB_SELECT_MODE",
    ADD_PUBLISHER_SELECTED: "ADD_PUBLISHER_SELECTED",
    REMOVE_PUBLISHER_SELECTED: "REMOVE_PUBLISHER_SELECTED",
    SELECT_ALL_PUBLISHERS: "SELECT_ALL_PUBLISHERS",
    DESELECT_ALL_PUBLISHERS: "DESELECT_ALL_PUBLISHERS",
    SET_STANDARD_VIEW_TYPE: "SET_STANDARD_VIEW_TYPE"
}