import { createReducer } from "@reduxjs/toolkit"
import { SETTINGS_TYPES } from './settings.types'

const INITIAL_STATE = {
    publishers: null,
    functionality: {},
    tableauDashboad: {},
    isLoading: false,
    error: null,
}

export const settingsReducer = createReducer(INITIAL_STATE, {
    [SETTINGS_TYPES.SET_SETTINGS]: (state, action) => {
        state.isLoading = false;
        state.functionality = action.payload.funzionalita;
        state.publishers = action.payload.sigleRami
        state.tableauDashboad = action.payload.tableauDashboad
    },
    [SETTINGS_TYPES.FETCH_SETTINGS_START]: (state) => { state.isLoading = true },
    [SETTINGS_TYPES.FETCH_SETTINGS_FAILURE]: (state, action) => { state.isLoading = false; state.error = action.payload },
})