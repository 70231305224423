import moment from 'moment'
import { checkAbbreviationForBookType } from '../services/comparator.services'
import { URLS } from "./pathnames.constant";
import queryString from "query-string";
import { ZoomInOutlined } from "@ant-design/icons";
import React from 'react'
import { Link } from 'react-router-dom';

export const BOOK_TYPES = {
    PHYSICAL: {
        label: "Cartaceo", value: "cartaceo"
    },
    EBOOK: {
        label: "eBook", value: "eBook"
    },
    AUDIOBOOK: {
        label: "Audiolibro", value: "audiolibro"
    },
};

export const PAGE_ANIMATION = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { ease: "easeOut", duration: 0.2 },
}

export const BOOK_ABBREVIATONS = {
    NORMAL: {
        values: [
            "ESP",
            "LI",
            "LID",
            "LIPI",
            "LISP",
            "LISV",
            "LI9",
            "ESPQ",
            "LIX",
            "ALEA",
            "CA",
            "CALI",
            "LIPR",
            "LID7",
            "ALSV"
        ],
        name: BOOK_TYPES.PHYSICAL.label,
        color: "#fc5d5d"
    },
    EBOOK: {
        values: ["EPUB", "PDF", "POD"],
        name: BOOK_TYPES.EBOOK.label,
        color: "#00D14D"
    },
    AUDIOBOOK: {
        values: ["MP3", "SUB"],
        name: BOOK_TYPES.AUDIOBOOK.label,
        color: "#546BFF"
    },
    NOINFO: "NOINFO"
};

export const ORDER_BY_STANDARD = {
    EAN: {
        value: "ean",
        label: "EAN"
    },
    TITLE: {
        value: "titolo",
        label: "Titolo"
    },
    AUTHOR: {
        value: "autore",
        label: "Autore"
    },
    SOLD: {
        value: "venduto",
        label: "Venduto"
    },
    RELEASE_DATE: {
        value: "dataUscita",
        label: "Data uscita"
    },
}

export const STANDARD_TABLE_COLUMNS = [
    {
        title: 'Titolo',
        width: 150,
        dataIndex: 'titolo',
        key: 'titolo',
        sorter: (a, b) =>
            b.titolo?.localeCompare(a.titolo) || b.autore?.localeCompare(a.autore) || a.dataUscita?.localeCompare(b.dataUscita),
        sortDirections: ["descend"],
        showSorterTooltip: false,
        defaultSortOrder: "descend",
    }, {
        title: 'Autore',
        dataIndex: 'autore',
        key: 'autore',
        width: 100,
        sorter: (a, b) =>
            b.autore?.localeCompare(a.autore ?? 0),
        sortDirections: ["descend"],
        showSorterTooltip: false,
    },
    {
        key: 'ean',
        dataIndex: 'ean',
        width: 35,
        align: "right",
        render: (val, type) => <Link to={URLS.DETAILS.url +
            "?" +
            queryString.stringify({
                ean: val || 0,
                type: checkAbbreviationForBookType(type.tipoProdotto),
            })} > <ZoomInOutlined className="st_row_icon" />
        </Link >
    }, {
        title: 'Edit.',
        dataIndex: 'editore',
        key: 'editore',
        align: "right",
    }, {
        title: 'Prezzo',
        dataIndex: 'prezzo',
        key: 'prezzo',
        align: "right",
        render: (price) => price ? price.toFixed(2).toLocaleString().replaceAll(".", ",") + "€" : null
    }, {
        title: 'Dt. Uscita',
        dataIndex: 'dataUscita',
        key: 'dataUscita',
        width: 100,
        align: "right",
        sorter: (a, b) =>
            (a.dataUscita ? a.dataUscita : "").localeCompare(
                b.dataUscita ? b.dataUscita : ""
            ),
        sortDirections: ["descend"],
        showSorterTooltip: false,
        render: (date) => date ? moment(date).format("DD-MM-YYYY") : null
    }, {
        title: 'Tipo',
        dataIndex: 'tipoProdotto',
        key: 'tipoProdotto',
        align: "right",
        render: (type) => type
    }, {
        title: 'Tirat.',
        dataIndex: 'tiratura',
        key: 'tiratura',
        align: "right",
        render: (val) => val?.toLocaleString().replaceAll(",", ".")
    }, {
        title: 'Vend.',
        dataIndex: 'venduto',
        key: 'venduto',
        align: "right",
        render: (val) => val?.toLocaleString().replaceAll(",", "."),
        sortDirections: ["descend"],
        showSorterTooltip: false,
        sorter: (a, b) => a.venduto - b.venduto,
    }, {
        title: 'Giac.',
        dataIndex: 'giacenza',
        key: 'giacenza',
        align: "right",
        render: (val) => val?.toLocaleString().replaceAll(",", ".")
    }, {
        title: 'V. GFK',
        dataIndex: 'selloutGFK',
        key: 'selloutGFK',
        align: "right",
        render: (val) => val?.toLocaleString().replaceAll(",", ".")
    }, {
        title: 'GFK U12M',
        dataIndex: 'gfku12m',
        key: 'gfku12m',
        align: "right",
        render: (val) => val?.toLocaleString().replaceAll(",", ".")
    }, {
        title: 'Ean',
        width: 130,
        dataIndex: 'ean',
        key: 'ean',
        align: "right",
    }]


export const SOLD_GEMS_MODE = "Venduto GeMS";
export const SOLD_GFK_MODE = "Sellout GFK + digitale";
export const SOLD_VALUE_MODE = "Valore";