export const STANDARD_FILTERS = {
    EAN_LABEL: "Codice",
    EAN_INPUT: "Inserisci un codice ean completo o parziale",
    AUTHOR_LABEL: "Autore",
    AUTHOR_INPUT: "Inserisci un autore",
    TITLE_LABEL: "Titolo",
    TITLE_INPUT: "Inserisci un titolo",
    TYPE_LABEL: "Tipo prodotto",
    START_SEARCH: "Avvia ricerca",
    CLEAR_FILTERS: "Pulisci",
    PUBLISHERS_LABEL: "Editori",
    PUBLISHERS_NO_SELECTED: "Nessun editore selezionato",
    ONE_SELECTED: 'selezionato',
    MULTIPLE_SELECTED: "selezionati"
}

export const STANDARD = {
    ORDER_BY: "Ordina per",
    SELECT_ELEMENTS: "Seleziona",
    SELECT_ALL: "Seleziona tutti",
    DESELECT_ALL: "Deseleziona tutti",
    RESULTS_SELECTED: " risultati selezionati",
    RESULT_SELECTED: " risultato selezionato",
    LOADING: "Caricamento...",
    NO_RESULTS_TITLE: "Nessun risultato",
    NO_RESULTS_DESCRIPTION: "La tua ricerca non ha portato risultati",
    REPORT: "Distr. Venduto",
    TOTALIZE: "Totalizza"
}

export const SOLD_REPORT = {
    DETAILS: "Dettagli",
    PROPORTION: "Proporzione sul totale",
    SOLD_COPIES: "Numero ",
    NOTHING_SELECTED_TITLE: "Nessun risultato selezionato",
    NOTHING_SELECTED_DESCRIPTION: "Effettua una ricerca oppure seleziona i risultati desiderati",
    TOTAL_SOLD: "Totale ",
    COPIES_SOLD: " copie vendute"
}

export const LOGIN = {
    SISMINO: "SISMINO",
    LOGIN_BUTTON: "Login",
    ERRORS: {
        500: "Si è verificato un errore, riprovare più tardi. Se l'errore persiste contattare un amministratore",
        418: "Il tuo account non è abilitato per accedere a Sismino",
        401: "Effettua il login per continuare",
        403: "Il tuo account è momentaneamente disabilitato",
        404: "Il tuo account non è abilitato per accedere a questa app"
    }
}

export const FOUR04 = {
    DESCRIPTION: "La pagina che stai cercando non esiste"
}

export const TOTALIZE = {
    ERROR_TITLE: "Si è verificato un errore",
    ERROR_DESCRIPTION: "La dashboard di tableau che stai cercando non esiste oppure l'url della pagina non è valido"
}

export const DETAILS = {
    ERROR_TITLE: "Si è verificato un errore",
    ERROR_DESCRIPTION: "La dashboard di tableau che stai cercando non esiste oppure l'url della pagina non è valido"
}

export const PUBLISHER_FILTERS = {
    SEARCH_INPUT: "Cerca editori...",
    DESELECT_ALL: STANDARD.DESELECT_ALL,
    SELECT_ALL: STANDARD.SELECT_ALL,
    SINGLE_SELECTED: " editore selezionato",
    MULTIPLE_SELECTED: " editori selezionati"
}