import { createReducer } from "@reduxjs/toolkit"
import { USER_TYPES } from './user.types'

const INITIAL_STATE = {
    currentUser: null,
    favPublisher: [],
    isLoading: false,
    error: null,
    isPublishersManuallyEmpty:false,
}

export const userReducer = createReducer(INITIAL_STATE, {
    [USER_TYPES.SET_USER]: (state, action) => {
        state.currentUser = action.payload;
        state.isLoading = false;
        state.error = false
    },
    [USER_TYPES.SET_FAV_PUBLISHER]: (state, action) => { state.favPublisher = action.payload.map(pub => pub) },
    [USER_TYPES.FETCH_LOGIN_START]: (state) => { state.isLoading = true },
    [USER_TYPES.FETCH_LOGIN_FAILURE]: (state, action) => { state.isLoading = false; state.error = action.payload },
    [USER_TYPES.ADD_FAV_PUBLISHER]: (state, action) => { state.favPublisher.push(action.payload) },
    [USER_TYPES.REMOVE_FAV_PUBLISHER]: (state, action) => { if (state.favPublisher.length - 1 <= 0) { state.isPublishersManuallyEmpty = true } state.favPublisher = state.favPublisher.filter((pub) => action.payload.code !== pub.code); }
})