//COMPARATORS TRANSFORM SOME DATA INTO OTHER DATA THAT IS A STRING OR A NUMBER

import { BOOK_ABBREVIATONS } from '../constants/values.constant'

export function checkAbbreviationForBookType(abbr) {
    const abbreviaton = abbr ? abbr : ""
    if (BOOK_ABBREVIATONS.NORMAL.values.includes(abbreviaton)) {
        return BOOK_ABBREVIATONS.NORMAL.name
    } else if (BOOK_ABBREVIATONS.EBOOK.values.includes(abbreviaton)) {
        return BOOK_ABBREVIATONS.EBOOK.name
    } else if (BOOK_ABBREVIATONS.AUDIOBOOK.values.includes(abbreviaton)) {
        return BOOK_ABBREVIATONS.AUDIOBOOK.name
    } else {
        return BOOK_ABBREVIATONS.NOINFO
    }
}

export function convertCardTypeToCardColor(type) {
    switch (type) {
        case BOOK_ABBREVIATONS.NORMAL.name:
            return BOOK_ABBREVIATONS.NORMAL.color;
        case BOOK_ABBREVIATONS.EBOOK.name:
            return BOOK_ABBREVIATONS.EBOOK.color;
        case BOOK_ABBREVIATONS.AUDIOBOOK.name:
            return BOOK_ABBREVIATONS.AUDIOBOOK.color;
        default:
            return "#8a8a8a";
    }
}

export function handleTableRowClass(result) {
    const type = checkAbbreviationForBookType(result.tipoProdotto);
    switch (type) {
        case BOOK_ABBREVIATONS.NORMAL.name:
            return "st_border_normal";
        case BOOK_ABBREVIATONS.EBOOK.name:
            return "st_border_ebook";
        case BOOK_ABBREVIATONS.AUDIOBOOK.name:
            return "st_border_audiobook";
        default:
            return "st_border_undefined";
    }
}