export const BASE_URL = "/sisma/mobile"
export const PROTECTED_URL = '/gestionale'
export const STANDARD_URL = PROTECTED_URL + '/standard'
export const STANDARD_FILTERS = STANDARD_URL + '/filtri'

export const URLS = {
    LOGIN: { url: '/login' },
    STANDARD: {
        url: STANDARD_URL
    },
    STANDARD_FILTERS: {
        url: STANDARD_FILTERS
    },
    DETAILS: {
        url: PROTECTED_URL + "/dettagli"
    },
    SOLD_REPORT: {
        url: PROTECTED_URL + "/report"
    },
    TOTALIZE: {
        url: PROTECTED_URL + "/totalizza"
    },
    PUBLISHER_FILTER: {
        url: STANDARD_FILTERS + "/editori"
    }
}