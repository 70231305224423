import axios from "axios";
import queryString from "query-string"

let ax;

if (process.env.NODE_ENV === 'development') {
    ax = axios.create({
        baseURL: "http://localhost:8080/sisma"
    });
} else {
    ax = axios.create({
        baseURL: "/sisma"
    });
}

const CONTROLLERS = {
    SEARCH: "/search",
    SETTINGS: "/settings",
    USER: "/user",
    AUTH: "/auth",
}

const METHODS = {
    GET_AUTHORS: CONTROLLERS.SEARCH + "/filters/autori",
    GET_TITLES: CONTROLLERS.SEARCH + "/filters/titoli",
    GET_STANDARD_LIST: CONTROLLERS.SEARCH + '/sismino',
    GET_SETTINGS: CONTROLLERS.SETTINGS + '/list',
    GET_FAKE_LOGIN: CONTROLLERS.USER + "/",
    GET_LOGIN: CONTROLLERS.AUTH + "/login",
    GET_SEARCHID: CONTROLLERS.SEARCH + "/totalizza/salva",
    GET_FAVOURITE_TYPE: CONTROLLERS.USER + "/preferiti/filtri",
    GET_VIEW_TYPE: CONTROLLERS.USER + "/preferiti/mobile?",
    GET_EDITORS: CONTROLLERS.USER + "/preferiti/editori/sismino"
}

/* -------------------------- COMPONENT/PAGE CALLS -------------------------- */

export function getAuthors(success, failure) {
    ax.get(METHODS.GET_AUTHORS).then((res) => {
        success(res.data)
    }).catch((err) => { failure(err) })
}

export function getTitles(success, failure) {
    ax.get(METHODS.GET_TITLES).then((res) => {
        success(res.data)
    }).catch((err) => { failure(err) })
}

export function getViewType(success, failure, username) {
    ax.get(METHODS.GET_VIEW_TYPE + queryString.stringify({ username: username })).then((res) => {
        const { data } = res
        let value = true;
        if (data && data[0]) {
            if (JSON.parse(data[0].payload).result_view === "grid") {
                value = false
            }
        }
        success(value)
    }).catch((err) => { failure(err) })
}

/* ------------------------------- SAGA CALLS ------------------------------- */

export const getStandardResults = (data) => ax.post(METHODS.GET_STANDARD_LIST, data)

export const getSettings = () => ax.get(METHODS.GET_SETTINGS)

export const getFavPublisher = (username = "") => ax.get(METHODS.GET_EDITORS + "?" + queryString.stringify({ username: username }))

/**
 * @description only used when process.env.NODE_ENV === "development" so SAML login is skipped
 */
export const getFakeLogin = () => ax.get(METHODS.GET_FAKE_LOGIN + "dev.account")

export const getLogin = () => ax.get(METHODS.GET_LOGIN)

export const getSearchId = (data) => ax.post(METHODS.GET_SEARCHID, data)

export const getFavouriteTypeFilter = (username = "") => ax.get(METHODS.GET_FAVOURITE_TYPE + "?" + queryString.stringify({ username: username }))




